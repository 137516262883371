@import "./libs/mixing";

body {
  box-sizing: content-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Josefin Sans", sans-serif;
  &::-webkit-scrollbar {
    width: 0.1em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: black;
  }
  &::-webkit-scrollbar-track {
    background: rgb(207, 207, 207);
  }
  // @include response-media-height() {
  //   @media (orientation: landscape) {
  //     display: none;
  //   }
  // }
}
