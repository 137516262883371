.appmodal {
    width: 100%;
    height: 100vh;
    background: rgb(50, 50, 50);
    display: flex;
    opacity: .8;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    button {
      width: 25vh;
      height: 5vh;
      color: black;
      background: white;
      filter: brightness(200%);
      border: none;
      text-transform: uppercase;
    }
    h4 {
      width: 90%;
      color: white;
      text-align: center;
    }
    img {
      width: 15em;
      height: auto;
    }
  }
  