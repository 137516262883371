@import "../../libs/mixing";
@import "../../libs/variables";
@import "../../libs/animationMixing";



//////////////////////////////////////////////////////////////////////
// main wrapper component for header-photo-background & container-info
//////////////////////////////////////////////////////////////////////
.main-wrapper-container {
  width: 100%;
  height: 100%;
  display: flex;
  

  @include response-media(phone_tablet) {
    flex-flow: column;

    
  }
}

///////////////////////////////////////////////////////////////////////////////
// container photo layout
/// helps with the photo background position
/// controls the height of the pic
.main-photo-container {
  width: 40%;
  height: 100vh;

  overflow: hidden;

  @include response-media(phone_tablet) {
    width: 100%;
  }
}

// background photo
.main-photo-background {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.1)),
    url("../../images/gymphoto.webp");
  background-repeat: no-repeat, no-repeat;
  background-size: cover;

  color: #fffcfcd5;
  width: 40%;
  height: 100%;
  z-index: 100;

  @include response-media(phone_tablet) {
    position: relative;
    width: 100%;
    height: 100%;
  }
  @include response-media(phone) {
    top: 0; // overrides the previous property of top: from phone_tablet
  }
}

.main-photo-info-container {
  width: 90%;
  height: 100%;
  display: flex;

  flex-direction: column;
  justify-content: space-between;

  @include response-media(phone_tablet) {
    width: 90%;
    height: auto;
    justify-content: center;
  }

  // border: 2px red solid;
}

.main-header-title {
  width: 100%;
  height: 50%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  font-size: 1.6em;
  overflow: hidden;
  text-transform: capitalize;



  h1 {
    margin: 0;
    margin-bottom: 0.6em;
    text-shadow: 2px 2px 4px rgba(15, 15, 15, 0.926);
  }
 
  @include response-media(xlarge) {
    font-size: 1.3em;
  }
  @include response-media(large) {
    font-size: 1.15em; // max font-zise for iphone
  }
  @include response-media(phone_tablet) {
    // height: auto;
    height: 60%;
    justify-content: center;
    align-items: center;
    margin-bottom: 4em; // makes the information to look more centered
  }
}
///////////////////////////////////////
/// MainTitleAnimation
.main-title-animation-wrapper {
  width: 85%;
  height: auto;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  font-size: 0.8em;
 
  h4 {
    margin: 0;
    margin-bottom: 0.6em;
    text-shadow: 2px 2px 4px rgba(15, 15, 15, 0.926);
    @include keyframes-response(opacityani);
    @include animated("opacityani 2s ease-in-out");
    
   
  }
  @include response-media(xlarge) {
    font-size: 0.9em;
    // padding-left: 0;
  }
  @include response-media(phone_tablet) {
    // font-size: .9em;
    font-size: 0.8em;
    width: 100%;
    text-transform: uppercase;
   
    // padding-left: 0;
  }
}

.main-arrow-up-icon-container {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1em;
  h6 {
    margin: 0;
    color: gray;
  }
}
