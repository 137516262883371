//////////////////////////////////////////////
/// Animations
//  it creates a sequence of styles to be applied to an
// element over a set of animation points.
// opacityani is for background modal
@mixin keyframes-response($element) {
  @if ($element == frameani) {
    // navekeyframes is the assigned to keyframes
    @keyframes #{frameani} {
      from {
        transform: translateY(-200%);
      }
      to {
        transform: translateY(0);
      }
    }
  } @else if ($element == frameanileft) {
    // navekeyframes is the assigned to keyframes
    @keyframes #{frameanileft} {
      from {
        transform: translateX(-200%);
      }
      to {
        transform: translateX(0);
      }
    }
  } @else if ($element == frameright) {
    @keyframes #{frameright} {
      from {
        transform: translateX(200%);
      }
      to {
        transform: translateX(0);
      }
    }
  } @else if($element == opacityani) {
    @keyframes #{opacityani} {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0;
      }
      50% {
        opacity: 0.3;
      }
      75% {
        opacity: 0.7;
      }
      100% {
        opacity: 0.9;
      }
    }
  }
}

// the element will smoothly transition through the specified
// keyframe points in keyframes-response(){@content}, applying
// the styles defined at each point, creating the animation effect.
@mixin animated($animation_properties) {
  -webkit-animation: #{$animation_properties};
  -moz-animation: #{$animation_properties};
  -ms-animation: #{$animation_properties};
  -o-animation: #{$animation_properties};
  animation: #{$animation_properties};
}

//////////////////////////////////////////////
/// MODAL PROPERTIES
// center the modal based on the root properties
@mixin modal-position($position) {
  @if ($position == normal) {
    top: 0;
    left: 0;
  } @else if($position == right) {
    top: 0;
    right: 0;
  }
}

// adapting to full screen and half screen in the UI
@mixin background-web-layout($heightValue, $backColor) {
  position: fixed;
  background-color: $backColor;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: $heightValue;
  z-index: 1900;
  opacity: 0.7;
}
// structure and position of the modal's elements
// including the modal on top of the background
@mixin modal-web-layout($heightValue, $backColor) {
  position: fixed;
  background: $backColor;
  z-index: 1900;
  width: 100%;
  height: $heightValue;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: hidden;
}
