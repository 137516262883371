//////////////////////////////////////////////
// 1em = 16px Ex: 576 / 16
// 1em mesurement works better for browsers
// ipad mini 820px
// these variables are used for the media querie
$smh: 27.25em; // 432px
$xs: 31em; // 533px
$sm: 35em; // 576px
$md: 51.25em; // 820px
$lg: 62em; //992px;
$xl: 75em; // 1200px;
$xxl: 1400px;
//////////////////////////////////////////////

// font-size: 1rem = 16px -> if you want 8px, then 8/16*100 result will be in percentage
// @mixin response-phone {
//     @media (max-width: $sm){ @content };
// }

// @mixin reusability for media use
// @content allow to input an entire code block
// @breakpoint is the parameter being passed
// from larger to small order to as expexted
//////////////////////////////////////////////
/// MEDIA QUIERIE MANAGER
@mixin response-media($breakpoint) {
  @if ($breakpoint == xlarge) {
    @media (max-width: $xl) {
      @content;
    } // 820px
  } @else if ($breakpoint == large) {
    @media (max-width: $lg) {
      @content;
    } // 992px
  } @else if($breakpoint == phone_tablet) {
    @media (max-width: $md) {
      @content;
    } // 820px
  } @else if($breakpoint == phone) {
    @media (max-width: $sm) {
      @content;
    } // 576px
  } @else if($breakpoint == xsmall) {
    @media (max-width: $xs) {
      @content;
    } //
  }
}

// used in showcase-parallax-container
@mixin response-media-height() {
  @media (max-height: $smh) {
    @content;
  } // 1300px
}
// useable properties for response-media
@mixin resusable-media-properties() {
  padding: 2.5rem * 1.5;
  text-align: center;
  align-items: center;
  position: absolute; // this position will make the scroll behavior work in the entire page for mobiles
}
//////////////////////////////////////////////

//////////////////////////////////////////////
/// MODAL PROPERTIES
// it creates a sequence of styles to be applied to an
// element over a set of animation points.
@mixin keyframes-response($element) {
  @if ($element == navkeyframes) {
    @keyframes #{navkeyframes} {
      @content;
    }
  }
}

// the element will smoothly transition through the specified
// keyframe points in keyframes-response(){@content}, applying
// the styles defined at each point, creating the animation effect.
@mixin modal-animation($animation_properties) {
  -webkit-animation: #{$animation_properties};
  -moz-animation: #{$animation_properties};
  -ms-animation: #{$animation_properties};
  -o-animation: #{$animation_properties};
  animation: #{$animation_properties};
}

// center the modal based on the root properties
// in this case: modalWindow is based on root having (top: 0),
// navmenu based on the root having (top:10) css property in Modal.js
@mixin modal-position($position) {
  @if ($position == navmenu) {
    top: 15%;
    left: 10%;
  } @else if($position == modalWindow) {
    top: 50%;
    left: 50%;
  }
}
//////////////////////////////////////////////

//////////////////////////////////////////////
/// MENU CUSTOM BUTTON
@mixin button-style-component($backgroundStyle, $borderStyle) {
  width: 17em;
  position: relative;
  height: 2.5em;
  border: 4px ridge $borderStyle;
  background-color: transparent;
  // border-radius: .5em;
  border-left: none;
  border-right: none;
  border-top: none;
  // box-shadow: 1px 1px 4px 3px  rgba(0, 0, 255, .2);

  &::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 40%;
    background-color: $backgroundStyle;
    transition: 0.5s;
    transform-origin: center;
  }
  &::before {
    content: "";
    transform-origin: center;
    position: absolute;
    top: 80%;
    left: 0;
    width: 100%;
    height: 40%;
    background-color: $backgroundStyle;
    transition: 0.4s;
  }
  &:hover::after,
  &:hover::before {
    transform: scale(0);
  }
  &:active {
    transform: translateY(1px);
  }
}
//////////////////////////////////////////////
/// exported to CardMemebers.js
@mixin members-ui-style() {
  width: 100%;
  height: 31vh;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: white;

  perspective: 1000px;
  background: gray;
}
@mixin members-ui-style-title() {
  width: 100%;
  height: 3em;
  text-align: left;
  padding-left: 0.7em;
  background: white;
  color: white;
  // border-top: 5px solid $color;
}

////////////////////////////
/// MemberTableTwo.js
@mixin table-style-wrapper {
  width: 40%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
